import React from "react"
import { Link,graphql } from 'gatsby'

import Layout from "../../components/layout"
import Spacer from "../../components/spacer"
import Next from "../../components/next"
import Tooltip from "../../components/tooltip"

const Page = ({data}) => (
  <Layout title="Smartix" keywords={["rail api", "train ticket", "api"]} submenu={data.allRailapisubmenuJson.edges}>
    <content>
      <h1>Seat Reservations</h1>

      <blockquote>
        <span>Why do retailer offer options to their customer that they simply cannot fulfil?</span>
      </blockquote>

      <p>Seat reservations appear to be little more than a side issue for most <Tooltip>TIS</Tooltip> and most <Tooltip>TOC</Tooltip>s.
      We are astonished how little focus has been applied to what we see as a fundamental part of the customer experience.</p>

      <p>Customer satisfaction (and consequently, customer loyalty) is determined by meeting or exceeding their expectations.
      Fall short of those expectations and customers will be dissatisfied even if the overall product or service is good.</p>

      <p>Expectations will not be met if you give your customers a seating choice and they choose a "forward facing, window seat, near the toilet 
      in the quiet carriage" and you give them a "backwards facing seat nowhere near a toilet in some other carriage". You are better off not
      giving them any choice at all.</p>

      <p>Surprisingly, every rail ticket retailer suffers from this problem. They offer options that are simply not available, or
      rely upon <Tooltip>NRS</Tooltip> to select seats when it is fundamentally incapable of making an informed choice on your behalf.</p>

    </content>

    <Spacer>
      <p>The fundamental problems with the NRS that mean it will never meet your customer's expectations</p>
    </Spacer>

    <content>

      <blockquote>
        <span>The problem the NRS faces when choosing seats is missing and inaccurate data, poorly designed
        seating attributes as well as functionality that simply does not work.</span>
      </blockquote>

      <p>Every <Tooltip>TIS</Tooltip> (including those operated by the train companies themselves) relies upon 
      the <Tooltip>NRS</Tooltip> to choose seats. Seating requests are sent to <Tooltip>NRS</Tooltip> specifying
      the ticket type, passengers and optional seating preferences. The <Tooltip>NRS</Tooltip> is supposed to choose
      the best seats for your passengers. Unfortunately, it rarely chooses seats your passengers would actually want. 
      </p>

      <ul>
        <li><strong>Seat facing:</strong> Should be Forwards or Backwards, but apparently Airline (which can face either way) 
        is also a direction attribute! Generally, seat facing is only specified for table seats. Most seats have no facing 
        attribute associated with them. Also, of those that do, many are incorrect. We have seen seats side by side with one
        set as facing forward and the other backwards. It is little wonder that the <Tooltip>NRS</Tooltip> so often chooses
        the wrong seat!</li>

        <li><strong>Seat Position:</strong> Should be Aisle, Window or on a few services, Middle. Many seats do not have this 
        attribute and of those that do, a significant number are incorrect. We've seen aisle seats marked as window seats and 
        visa versa.</li>
        
        <li><strong>Aisle Position:</strong> Whilst not important to most passengers, for a few with mobility issues or injury,
        having a seat with the aisle to the left can make for a far more comfortable journey than being allocated a seat with
        the aisle to the right.</li>

        <li><strong>Near to:</strong> Some passengers have a preference to sit near to a luggage rack, or near to the toilets, 
        near the doors, middle of the carriage, or the end of the carriage etc. Sadly very few seats have these attributes set, 
        so there likely to be many suitable seats, the <Tooltip>NRS</Tooltip> has no idea which seats they are.</li>

        <li><strong>Near another seat:</strong> We tried to use this <Tooltip>NRS</Tooltip> option for the first time when we
        began to build <Link to="/smartix/" className="printLink">smartix</Link> and we taken aback by <Tooltip>RDG</Tooltip>'s
        response when we enquired as to why it was saying there was "no availability" when we asked for a seat "near to" another 
        seat when we knew that seat adjacent, in front and behind were all available. <Tooltip>RDG</Tooltip> told us that seats
        are grouped into small invisible groups (of between 2 and 16 seats). A seat is only considered "near to" another seat
        if it is inside that group. If it's not, then even if it is right next to the target seat, <Tooltip>NRS</Tooltip> does
        not consider it to be "near it". We have tried, but we cannot understand that logic!</li>
      </ul>

      <p>Clearly, any <Tooltip>TIS</Tooltip> using the <Tooltip>NRS</Tooltip> will inherit all the flaws and be hampered by
      its limitations, quirks and broken functionality.</p>

    </content>

    <Spacer>
      <p name="advanced-selection">This is why <Link to="/smartix/" className="printLink">smartix</Link> does not use the NRS for seat selection</p>
    </Spacer>

    <content>

      <p>Instead, we chose to build our own seat selection system. The database powering this is built from data extracted from 
      the <Tooltip>NRS</Tooltip> which is then augmented with a significant amount of additional data. We also correct all the 
      errors, such as seats facing the wrong way. We then overhauled the attribute groups so that Airline is now a seat type and 
      not a facing direction. Finally, seating options make sense!</p>

      <p>Referring back to the <Tooltip>NRS</Tooltip> fault list above, <Link to="/smartix/" className="printLink">smartix</Link> has
      resolved all of those issues and provides functionality that <em>simply works</em>.</p> 

      <p>We also offer <Link to="/smartix/reservations/#seat-maps">interactive seat maps</Link></p>

    </content>

    <Spacer>
      <p name="seating-options">Which seating options should you give to your customer?</p>
    </Spacer>

    <content>

      <blockquote>
        <span>As a retailer, if you use any other TIS, you are setting yourself up, right from the start, to fail to meet your customers expectations.</span>
      </blockquote>

      <p>If you look at any website selling train ticket and get as far as specifying your seating options you'll notice that
      you are always offered the exact same options: Facing, whether you want a table seat or not, Position: window or aisle and 
      whether you want to be seated in the quiet coach or not. Every single <Tooltip>TIS</Tooltip> does this! Why?</p>

      <p>Even if that service has no quiet coach, or has no table seats, you still get the same set of options...</p>

      <p><Link to="/smartix/" className="printLink">smartix</Link> works differently. Before providing the option list to your 
      customers, you will send a request to our API and we will tell you what options are available on the train your customer
      will be travelling on. Even better, the list we will give you is calculated from live availability. So, if the service, for
      example, has table seats, but they are all reserved, then we will not give you that option. You can, therefore, be 
      confident that any options you offer to your customer can be fulfilled.</p>

      <p>Some options are only applicable to Standard Class tickets, or First Class tickets. For example, the quiet coach is only ever
      applicable to Standard Class. Individual Seats are normally only found in First Class coaches. So, we give you separate lists for
      Standard and First class so that you can further limit the options you present based on which ticket the customer is purchasing.</p>

    </content>

    <Spacer>
      <p name="split-tickets">Split Ticket Support</p>
    </Spacer>

    <content>

      <p>When reserving seats for <Link to="/smartix/split-ticketing/">Split Ticketing</Link> journeys, there are unique requirements that 
      the <Tooltip>NRS</Tooltip> makes no attempt to meet. Other <Tooltip>TIS</Tooltip> that rely on <Tooltip>NRS</Tooltip> for seat selection can almost guarantee that their
      customers will need to change seats when their ticket changes and probably also change carriages (assuming they require reservations). For 
      advance purchase tickets where the customer <strong>must</strong> sit in their allocated seat, this is a major inconvenience.</p>

      <p><Link to="/smartix/" className="printLink">print's</Link> seat selection has <Link to="/smartix/split-ticketing/">Split Ticketing</Link> at
      the core and is designed to ensure your customer have the least possible disruption, in most cases, allocating the same seat for all their tickets.</p>

    </content>

    <Spacer>
      <p name="seat-maps">Interactive Seat Selection Maps</p>
    </Spacer>

    <content>

      <blockquote>
        <span>Would you like to see an interactive seat selection demo?</span>
        <cite><Link to="/contact-us">drop us a line</Link></cite>
      </blockquote>

      <p>Interactive Seat Maps can be presented to your customers so that they can choose the exact seat that they wish to reserve. Filters can be used to
      indicate which seats match their criteria. For example, you can highlight only forward facing seats, or only seats that are near to luggage racks.</p>


      <p>Maps are also ideal for use when confirming bookings by giving your customer a visual indication of where they can find their seats when they board 
      the train.</p>

      <Next submenu={data.allRailapisubmenuJson.edges}/>

    </content>
  </Layout>
)

export const query = graphql`
  query {
    allRailapisubmenuJson {
      edges {
        node {
          title
          path
        }
      }
    }
  } 
`

export default Page
