import React from "react"

import './spacer.less'

const Spacer = (props) => (
  <div className="spacer">
    <content>
      {props.children}
    </content>
  </div>
)

export default Spacer